export default {
	//Error messages
	INVALID_ORIGIN: 'ER001',
	INVALID_DESTINATION: 'ER002',
	INVALID_SHIPPING_DATE: 'ER003',
	INVALID_SHIPPING_ITEM: 'ER004',
	INVALID_PIECES: 'ER005',
	INVALID_LENGTH: 'ER006',
	INVALID_WIDTH: 'ER007',
	INVALID_HEIGHT: 'ER008',
	INVALID_WEIGHT: 'ER009',
	INVALID_ULD_TYPE: 'ER010',
	INVALID_COUNTOUR: 'ER011',
	INVALID_ARRIVAL_DATE: 'ER012',
	SAME_ORG_DEST_ERROR: 'ER022',
	CORRECT_ERRORS_BFR_CONTINUE: 'ER013',
	INVALID_UNID: 'ER266',
	INVALID_REPORT_START_DATE: 'ER267',
	INVALID_REPORT_END_DATE: 'ER268',
	PROVISON_ERROR: 'ER270',
	INVALID_ALLOTMENT_ID: 'ER272',
	ENTER_FLIGHT_NUMBER: "ER273",
	ENTER_VALID_FLIGHT_DATE: "ER274",
	ENTER_VALID_ADDRESS: "ER275",

	//information messages
	LOADING: 'ER128',
	SEARCHING: 'ER043',
	CHOOSE_CATEGORY: 'ER014', //'Choose a category',
	CHOOSE_CONT_TYPE: 'ER015', //Choose a container type',
	CHOOSE_TEMP_RANGE: 'ER016', //'Choose a temperature range',
	IS_DRY_ICE: 'ER017', //'Does your shipment contain Dry Ice?',
	SLCT_SHP_TYPE: 'ER018',
	SLCT_HYBRID_CON_TYPE: 'ER019',
	SELECT_TEMP_RANGE: 'ER020', //'Select a temperatue range',
	CMPLT_MNDTRY_FLDS: 'ER021',
	SLCT_ACTIVE_CON_TYPE: 'ER209',
	SELECTED: 'ER024', //"You've already selected this",
	ASSISTANCE_MSG: 'ER027',
	//"Thank you, we've sent your enquiry to our team. They'll get back to you as soon as they can.",
	OPTN_NOT_CMPTBL: 'ER028',
	NO_FLIGHTS: 'ER039',
	POP_UP_LABEL_SHARE_BOOKING: 'ER108',
	POP_UP_TEXT_SHARE_BOOKING: 'ER109',
	POP_UP_TEXT_BOX_INFO: 'ER052',
	POP_UP_CANCEL_TEXT: 'ER131',
	ADD_SERVICE_INFO: 'ER208', //'Add on services may incur an additional cost.'
	BKG_TAB_MESSAGE_LOOSE: 'ER033',
	BKG_TAB_MESSAGE_ULD: 'ER034',
	INVALID_FLIGHT_NUMBER: 'ER218',
	INVALID_EMAIL: 'ER053',
	ASSISTANCE_MSG_CONTAINER: 'ER025',
	ASSISTANCE_MSG_SHIPPING: 'ER026',
	CHARGEABLE_WT_DIFFER_MSG: 'ER031',
	PIECE_LEVEL_WT_DISREGARD: 'ER032',
	MAIL_ASSISTANCE_FAILS: 'ER210',
	CNT_BASED_SCC_REMOVAL: 'ER030',
	COMPATIBILITY_ERROR: 'ER029',
	PIVOT_WT_INFO_MSG: 'ER035',
	NO_FLIGHT_TO_BOOK: 'ER211',
	FASTTRACK_BANNER_MSG: 'ER212',
	SHARE_QUOTE_POP_UP_TEXT: 'ER051',
	SHARE_CONFIRMATION_FAILED: 'ER112',
	SHARE_BOOKING_DETAILS_FAILED: 'ER113',
	SHARE_CONFIRMATION_SUCCESS: 'ER114',
	SHARE_QUOTE_FAILED: 'ER066',
	SHARE_QUOTE_SUCCESS: 'ER054',
	PRINT_INFO_MSG: 'ER138',
	INVALID_AWB: 'ER048',
	ENTER_CARRIER_CODE: 'ER148',
	PROCESS_REQUEST_FAILED: 'ER076',
	NO_BOOKINGS: 'ER125',
	ERR_CANCEL_PAST_BOOKING: 'ER133',
	ERR_MODIFY_PAST_BOOKING: 'ER135',
	ERR_MODIFY_BOOKING: 'ER280',
	MODIFY_BOOKING_SUCCESS: 'ER136',
	ALREDAY_CANCELLED: 'ER127',
	EMPTY_AWB_NUMBER: 'ER190',
	VALID_AWB_NUMBER: 'ER048',
	EMPTY_AWB_PREFIX: 'ER193',
	FIELD_REQUIRED: 'ER188',
	CORRECT_ERRORS_IN_AWB: 'ER149',
	ENTER_ALPHANUMERIC: 'ER156',
	ENTER_NUMERIC: 'ER161',
	ENTER_ALPHABETIC: 'ER158',
	VALUE_SHOULD_BE_DECIMAL: 'ER165',
	VALID_SLAC_PCS: 'ER179',
	VALID_CH_WT: 'ER157',
	SPOT_RATE_DATA_MISMATCH: 'ER279',
	ENTER_VALID_PHN: 'ER162',
	ENTER_VALID_MAIL: 'ER103',
	CONSGN_NOTIFICATION_MSG: 'ER178',
	SHIPPER_NOTIFICATION_MSG: 'ER191',
	SELECT_NOTIFICATION_OPTION: 'ER151',
	ENTER_VALID_DATE: 'ER175',
	HS_CODE_SIX_IN_LEGTH: 'ER167',
	AWB_SAVE_FAILED: 'ER176',
	AWB_SAVE_FAILED_VALID_ERROR: 'ER277',
	AWB_SAVE_SUCCESS: 'ER177',
	ENTER_VALID_HAWB_NUMBER: 'ER182',
	VALIDATE_PARTICIPANT_NAME: 'ER195',
	ENTER_PARTICIPANT_NAME: 'ER187',
	ENTER_VALID_ZIP_CODE: 'ER180',
	ENTER_VALID_PHN_NO: 'ER198',
	CORRECT_ERRORS_BFR_SAVING: 'ER181',
	HAWB_SAVE_SUCCESS: 'ER200',
	HAWB_DELETE_SUCCESS: 'ER202',
	HAWB_SAVE_FAILURE: 'ER276',
	HAWB_SAVE_FAILURE_VALID_ERROR: 'ER278',
	USER_FIRST_NAME_MANDATORY: 'ER086',
	USER_LAST_NAME_MANDATORY: 'ER087',
	USER_MOB_NO_MANDATORY: 'ER088',
	USER_VALID_MOB_NO: 'ER089',
	USER_COUNTRY_CODE_MANDATORY: 'ER090',
	USER_VALID_TEL_NO: 'ER091',
	ENTER_ALPHABETES: 'ER092',
	USER_PROFILE_SAVE_SUCCESS: 'ER094',
	USER_PROFILE_SAVE_FAILED: 'ER095',
	NO_USERS_PRESENT: 'ER096',
	STATUS_UPDATE_POPUP_TEXT: 'ER097',
	STATUS_UPDATE_SUCCESS: 'ER098',
	STATUS_UPDATE_FAILED: 'ER099',
	MANDATORY_USER_ID: 'ER100',
	USER_ID_VALIDATION: 'ER101',
	ENTER_EMAIL_ADDRESS: 'ER102',
	USER_CREATION_SUCCESS: 'ER104',
	USER_CREATION_FAILED: 'ER105',
	USER_UPDATE_FAILED: 'ER106',
	USER_ID_ALREADY_EXISTS: 'ER227',
	USER_UPDATE_SUCCESS: 'ER107',
	MANDATORY_TEMP_NAME: 'ER071',
	TEMP_SAVE_FAILED: 'ER072',
	TEMP_MODIFY_FAILED: 'ER074',
	TEMP_NAME_EXISTS_ERROR: 'ER073',
	TEMP_NOT_FOUND: 'ER075',
	MANDATORY_DRAFT_NAME: 'ER083',
	SAVE_DRAFT_ERROR: 'ER085',
	DRAFT_NAME_EXISTS: 'ER084',
	NO_TEMPLATES: 'ER070',
	TEMP_DELETE_SUCCESS: 'ER079',
	TEMP_DELETE_ERROR: 'ER080',
	NO_DATA_AVAILABLE: 'ER204',
	NO_ALLOTMENTS: 'ER205',
	ALLOTMENTS_FAILED: 'ER239',
	INCORRECT_MAIL_ENTERED: 'ER214',
	ENTER_VALID_CARRIER_CODE: 'ER217',
	ENTER_AWB_PREFIX: 'ER215',
	ENTER_VALID_AWB_PREFIX: 'ER216',
	AWB_NOT_FOUND: 'ER226',
	VALIDATE_PROMO_CODE_ERROR: 'ER141',
	VALIDATE_SPOT_RATE_ERROR: 'ER142',
	REST_NO_CAPACITY: 'ER044',
	REST_EMBRAGO: 'ER045',
	REST_LOADABILITY: 'ER047',
	REST_OTHER: 'ER046',
	REST_MULTIPLE: 'ER233',
	NO_ACCESS_PRIVILEGE: 'ER228',
	USER_MAPPED_ERROR: 'ER229',
	NO_DRAFT_FOUND: 'ER230',
	NO_BOOKINGS_AVAILABLE: 'ER231',
	UNEXPECTED_ERROR: 'ER232',
	EXCEL_UPLOAD_EMPTY_FIELDS: 'ER235',
	EXCEL_UPLOAD_EMPTY_FILE: 'ER237',
	CHOOSE_UPLOAD_FILE: 'ER236',
	DISCLAIMER_TEXT: 'ER238',
	VALID_AGENT_CODE: 'ER241',
	VALID_ADD_SCC_CODE: 'ER242',
	RESTRICTED_AGENT_DETAILS_ACCESS: 'ER243',
	ASSISTANCE_MSG_CONTAINER_DG: 'ER244',
	VALID_TOTAL_WEIGHT: 'ER245',
	VALID_LENGTH: 'ER246',
	VALID_WIDTH: 'ER247',
	VALID_HEIGHT: 'ER248',
	VALID_BREED: 'ER249',
	VALID_OTHER_BREED: 'ER249',
	VALID_YEARS: 'ER250',
	VALID_MONTHS: 'ER251',
	SPECIAL_INSTRUCTIONS: 'ER252',
	SNUB_NOSED: 'ER253',
	AVI_ASSISTANCE_MSG_SHIPPING_LABEL1: 'ER254',
	AVI_ASSISTANCE_MSG_SHIPPING_LABEL2: 'ER255',
	PET_EYEMBARGO_ERROR_MSG: 'ER269',
	HAWB_ALPHANUMERIC_MSG: 'ER189'

}
