import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import './capture-awb.scss'
import { getShippingItems } from '../../services/searchPanelServices'
import { getAWBDetails } from '../SearchPanel/templateUtils'
import { Alert } from '../../common/Alert'
import { UseInput } from '../../common/UseInput'
import BookingDetails from './Sections/BookingDetails'
import AgentDetails from './Sections/AgentDetails'
import {
	validateAWBDocNo,
	validateAWBPrefix,
	hasError,
	removeError,
} from './captureAwbUtils'
import LoadingIndicator from '../LoadingIndicator'
import { useTranslation } from 'react-i18next'
import Messages from '../../constants/Messages'
import pageNames from '../../constants/pageNames'
import { addError, pushPageData } from '../../utils/analytics'
import { PAGE_PRIMARY_CATEGORY, PAGE_SUB_CATEGORY1, CAPTURE_PAGE_SUB_CATEGORY2 } from '../../constants/index'
import Popup from '../Popup/Popup'

const CaptureAWB = ({
	setShipmentPrefix,
	setMasterDocumentNumber,
	handleTabChange,
}) => {
	//for multilingual
	const { t, i18n } = useTranslation(['labels', 'errors'])

	const [alertMessage, setAlertMessage] = useState('')
	const [status, setStatus] = useState(null)
	const [shippingItems, setShippingItems] = useState([])
	const [isLoading, setIsLoading] = useState(false)
	const [awbDetails, setAwbDetails] = useState(null)
	const [error, setError] = useState(null)
	const [chargeableWeight, setChargeableWeight] = useState(null)

	const [awbNo, setAwbNo] = useState('')
	const [awbPrefix, setAwbPrefix] = useState('607')
	const [accordionsActive, setAccordionsActive] = useState([])
	const [initialLoading, setInitialLoading] = useState(false)

	const [enableButtons, setEnableButtons] = useState(false)
	const [notificationType, setNotificationType] = useState(null)
	const [notificationMessage, setNotificationMessage] = useState('')
	const [popupDetails, setPopupDetails] = useState(null)

	const closeErrorPopup = () => {
		setPopupDetails(null)
	}

	useEffect(() => {
		;(async () => {
			try {
				let shippingItems = await getShippingItems()
				if (shippingItems) {
					shippingItems = shippingItems.sort((a, b) => {
						if (a.description < b.description) {
							return -1
						}
						if (a.description > b.description) {
							return 1
						}
						return 0
					})
				}

				setShippingItems(shippingItems)
				pushPageData(
					pageNames.AWB_DETAILS,
					PAGE_PRIMARY_CATEGORY.E_SERVICES,
					PAGE_SUB_CATEGORY1.CAPTURE,
					CAPTURE_PAGE_SUB_CATEGORY2.CAPTURE_AWB,
				)
			} catch (err) {
				addError(
					'SHIPPING_ITEM_API_ERROR',
					'Sorry, Shipping Details could not retrive now .Please try again later'
				)
				pushPageData(
					pageNames.AWB_DETAILS,
					PAGE_PRIMARY_CATEGORY.E_SERVICES,
					PAGE_SUB_CATEGORY1.CAPTURE,
					CAPTURE_PAGE_SUB_CATEGORY2.CAPTURE_AWB,
				)
			}
		})()
	}, [])

	const clear = () => {
		setAwbDetails(null)
		setError(null)
		setAlertMessage('')
		setStatus(false)
		setChargeableWeight(null)
		setShipmentPrefix(null)
		setMasterDocumentNumber(null)
		setAccordionsActive([])
	}
	const handleGetAwb = async (e) => {
		setInitialLoading(true)
		clear()
		if (
			validateAWBDocNo(awbNo, setError) &&
			validateAWBPrefix(awbPrefix, setError)
		) {
			setIsLoading(true)
			const data = await getAWBDetails(
				shippingItems,
				awbPrefix + awbNo,
				setIsLoading,
				false
			)
			if (data && !data.errorDescription) {
				setAwbDetails(data)
				setChargeableWeight(data.chargeableWeight)
				if(data && data.awbExecutionDetails &&
					data.awbExecutionDetails.issuedBy && data.awbExecutionDetails.issuedPlace)
					{ 
					 setEnableButtons(true)
					}
			} else if (data && data.errorDescription) {
				setStatus(false)
				setAlertMessage(data.errorDescription)
				addError('GET_AWB_API_ERROR', data.errorDescription)
			} else {
				setStatus(false)
				setAlertMessage(Messages.AWB_NOT_FOUND)
				addError(
					Messages.AWB_NOT_FOUND,
					i18n.getResource('en', 'errors', Messages.AWB_NOT_FOUND)
				)
			}
			setIsLoading(false)
		}
	}

	const handleAlertClose = () => {
		setStatus(false)
		setAlertMessage('')
		setNotificationMessage('')
		setNotificationType(null)
	}

	return (
		<div className='row capture-awb'>
			<div className='col-12 col-lg-12'>
				<div className='capture-awb-save '>
					{alertMessage !== '' && status !== null && (
						<Alert
							isError={!status}
							showAlert={true}
							message={t(alertMessage, { ns: 'errors' })}
							setTimeout={true}
							onAlertClose={handleAlertClose}
						/>
					)}
					<div className='form-row'>
						<div className='col-12'>
							<h4 className='section-heading pt-0 pl-1'>
								{t('COMMON.AWB_DTLS')}
							</h4>
						</div>
					</div>
					<div className='form-row'>
						<div className='col-12 col-lg-4'>
							<div className='form-row'>
								<div
									className='col-3 col-lg-3 pr-2 pb-3 pb-md-0 form-item form-item--text'
									data-module='molecules/form-item/FormItem'
								>
									<input
										id='awbPrefix'
										type='number'
										className='form-item__field'
										maxLength='3'
										value={awbPrefix}
										onChange={(e) => {
											if (e.target.value.length <= 3) {
												setAwbPrefix(e.target.value)
											}
										}}
										onBlur={(e) => {
											validateAWBPrefix(e.target.value, setError)
										}}
										aria-invalid={hasError('awbPrefix', error)}
										onFocus={(e) => {
											removeError(e, setError)
										}}
									/>
									<span className='form-item__error'>
										{error && error['awbPrefix']
											? t(error['awbPrefix'], { ns: 'errors' })
											: ''}
									</span>
								</div>
								<div
									className='col-9 col-lg-9 pl-1 form-item form-item--text'
									data-module='molecules/form-item/FormItem'
								>
									<input
										id='awbDocumentNo'
										type='number'
										className='form-item__field'
										maxLength='8'
										placeholder='AWB number'
										value={awbNo}
										onChange={(e) => {
											if (e.target.value.length <= 8) {
												setAwbNo(e.target.value)
											}
										}}
										onBlur={(e) => {
											validateAWBDocNo(e.target.value, setError)
										}}
										aria-invalid={hasError('awbDocumentNo', error)}
										onFocus={(e) => {
											removeError(e, setError)
										}}
									/>
									<span className='form-item__error'>
										{error && error['awbDocumentNo']
											? t(error['awbDocumentNo'], { ns: 'errors' })
											: ''}
									</span>
								</div>
							</div>
						</div>
						<div className='col-12 col-lg-2 captureAwb-Go'>
							<button className='button' onClick={handleGetAwb}>
								{t('COMMON.GO')}
							</button>
						</div>
					</div>
					
					{awbDetails && (
						<div className='booking-details mt-1'>
							<AgentDetails awbDetails={awbDetails} />
							<BookingDetails
								shippingItems={shippingItems}
								awbDetails={awbDetails}
								setAwbDetails={setAwbDetails}
								setShipmentPrefix={setShipmentPrefix}
								setMasterDocumentNumber={setMasterDocumentNumber}
								setError={setError}
								error={error}
								setIsLoading={setIsLoading}
								setStatus={setStatus}
								setAlertMessage={setAlertMessage}
								initChargeableWeight={chargeableWeight}
								handleTabChange={handleTabChange}
								accordionsActive={accordionsActive}
								setAccordionsActive={setAccordionsActive}
								initialLoading={initialLoading}
								setInitialLoading={setInitialLoading}
								enableButtons={enableButtons}
								setEnableButtons={setEnableButtons}
								setNotificationMessage={setNotificationMessage}
								setNotificationType={setNotificationType}
								setPopupDetails={setPopupDetails}
							/>
						</div>
					)}
					{popupDetails && popupDetails.display && (
						<Popup
							display={popupDetails.display}
							header={popupDetails.header}
							message={popupDetails.message}
							primaryBtnLbl={popupDetails.primaryBtnLbl}
							acceptFn={popupDetails.acceptFn}
							secondaryBtnLbl={popupDetails.secondaryBtnLbl}
							rejectFn={popupDetails.rejectFn}
							closeErrorPopup={closeErrorPopup}
						/>
					)}
					{isLoading ? <LoadingIndicator /> : <></>}
				</div>
			</div>
		</div>
	)
}

export default CaptureAWB
