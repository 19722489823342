import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import {
	DETAILS_APPLY_FOR, DIMENSION_UNIT,
	SHIPPING_TYPE,
	VOLUME_UNIT_VALUES,
	WEIGHT_UNIT,
	LOOSE_ITEM_KEYS,
} from '../constants'
import { RATE_TYPES } from '../constants/rateTypes'
import sessionKeys from '../constants/sessionKeys'
import calculateCbmVolume from '../utils/calculateCbmVolume'
import getPrincipal from './getPrincipal'
import {
	getBase64Decoded,
	getFromSessionStorage,
} from '../utils/StorageUtils'
import { ALLOWABLE_BOOKING_DATE, SIMILAR_BOOKING_SUGGESTIONS_PERIOD_DAYS } from '../configs/appConfigs'
import { calculateWeightToKg, round } from './common'
import getCurrentUser from './getCurrentUser'
import { getRandomIntInclusive } from './HttpRequestUtils'
import dgRestrictedSccs from '../configs/dgRestrictedSccs';

export const getQuoteRequestBody = (searchFormData) => {
	const sccCodes =
		searchFormData.sccCodes && searchFormData.sccCodes.length
			? searchFormData.sccCodes.map((e) => e.scc)
			: []
	const userDetails = getCurrentUser()
	const spotRateCode = searchFormData.spotRateCode ?
		searchFormData.spotRateCode :
		searchFormData.spotRateDetails && searchFormData.spotRateDetails.spotRateCode
			? searchFormData.spotRateDetails.spotRateCode
			: null
	const requestBody = {
		destination: searchFormData.destination
			? searchFormData.destination.code
			: null,
		origin: searchFormData.origin ? searchFormData.origin.code : null,
		// shippingDate: searchFormData.shippingDate
		// 	? moment(searchFormData.shippingDate).format('DD-MMM-YYYY')
		// 	: null,
		shippingDate: searchFormData.shippingDate
			? moment(searchFormData.shippingDate, 'DD-MMM-YYYY').format('DD-MMM-YYYY')
			: null,
		expectedArrivalDate: searchFormData.arrivalDate
			? moment(searchFormData.arrivalDate, 'DD-MMM-YYYY').format('DD-MMM-YYYY')
			: null,
		agentCode: userDetails ? userDetails.agentCode : null,
		currency: userDetails ? userDetails.currencyCode : null,
		weightUnit: WEIGHT_UNIT.KILOGRAM,
		volumeUnit: DIMENSION_UNIT.METERS,
		uniqueReferenceFour: searchFormData.priceCode,
		shipmentSccs: sccCodes,
		promoCode: searchFormData.promoCode,
		spotRateCode: spotRateCode,
		spotRateRequestedUser: searchFormData.spotRateDetails && searchFormData.spotRateDetails.spotRateRequestedUser
			? searchFormData.spotRateDetails.spotRateRequestedUser : null, // for handling spot rate booking modify
		services:
			searchFormData.appliedServices && searchFormData.appliedServices.length
				? searchFormData.appliedServices.map((e) => e.scc)
				: [],
		productDetails: {
			productName: searchFormData.shippingItemCode.serviceProduct || searchFormData.shippingItemCode.product,
		},
		commodityDetails: [
			{
				commodityCode: searchFormData.shippingItemCode
					? searchFormData.shippingItemCode.code
					: '',
				sccDetails: {
					sccCode: sccCodes ? sccCodes[0] : '',
				},
				pieces: searchFormData.totalPieces,
				weight: Number(searchFormData.totalWeightInKg).toFixed(2),
				volume: searchFormData.totalVolumeInMtrs
					? searchFormData.totalVolumeInMtrs.toFixed(2)
					: 0.01,
			},
		],
		flightNumber: searchFormData.flightNumber ? searchFormData.flightNumber : null,
		flightCarrierCode: searchFormData.flightCarrierCode ? searchFormData.flightCarrierCode : null,
	}
	if (searchFormData.shippingType === SHIPPING_TYPE.LOOSE) {
		requestBody.commodityDetails[0].dimensionDetaills =
			searchFormData.looseDetails && searchFormData.looseDetails.length
				? searchFormData.looseDetails.map((element) => {
					const weight = Number(element.weight) ? Number(element.weight).toFixed(2) : 0
					const weightType = !Number(element.weight)
						? DETAILS_APPLY_FOR.TOTAL
						: element.weightType
					return {
						length: element.length,
						height: element.height,
						width: element.width,
						numberOfPieces: element.pieces,
						weight:
							weightType === DETAILS_APPLY_FOR.PER_ITEM
								? weight * element.pieces
								: weight,
						tiltable: !element.turnable ? 'N' : 'Y',
						stackable: !element.stackable ? 'N' : 'Y',
						weightUnit: searchFormData.weightUnit,
						dimensionUnit: searchFormData.dimensionUnit,
					}
				})
				: []
	}
	if (searchFormData.shippingType === SHIPPING_TYPE.ULD) {
		requestBody.commodityDetails[0].uldInfoDetails =
			searchFormData.ULDDetails && searchFormData.ULDDetails.length
				? searchFormData.ULDDetails.map((element) => {
					return {
						uldWeight: element.weight,
						uldType: element.uldType,
						numberOfUlds: element.pieces,
						uldVolume: element.uldVolume,
					}
				})
				: []
		if (
			searchFormData.mixLooseDetails &&
			searchFormData.mixLooseDetails.length > 0 &&
			searchFormData.mixLooseDetails[0].weight !== ''
		) {
			requestBody.commodityDetails[0].dimensionDetaills =
				searchFormData.mixLooseDetails && searchFormData.mixLooseDetails.length
					? searchFormData.mixLooseDetails.map((element) => {
						const weight = Number(element.weight) ? Number(element.weight) : 0
						const weightType = !Number(element.weight)
							? DETAILS_APPLY_FOR.TOTAL
							: element.weightType
						return {
							length: element.length,
							height: element.height,
							width: element.width,
							numberOfPieces: element.pieces,
							weight:
								weightType === DETAILS_APPLY_FOR.PER_ITEM
									? weight * element.pieces
									: weight,
							tiltable: !element.turnable ? 'N' : 'Y',
							stackable: !element.stackable ? 'N' : 'Y',
							weightUnit: searchFormData.weightUnit,
							dimensionUnit: searchFormData.dimensionUnit,
						}
					})
					: []
		}
	}
	return requestBody
}

export const getFileList = async (
) => {
	const fileListFromSession = getFromSessionStorage('petFiles');
	const fileList = getBase64Decoded(fileListFromSession)
	return fileList;
}

export const getSaveBookingRequestBody = (

	searchFormData,
	selectedFlight,
	spotRatePayload,
	awbNumber,
) => {

	let requestBody = {}
	const userDetails = localStorage.getItem(sessionKeys.USER_DETAILS)
		? JSON.parse(localStorage.getItem(sessionKeys.USER_DETAILS))
		: null
	const sccCodes =
		searchFormData.sccCodes && searchFormData.sccCodes.length
			? searchFormData.sccCodes.map((e) => e.scc)
			: []

	//const {service} = selectedFlight
	let promoCode = searchFormData.promoCode && searchFormData.promoCode.length ?
		searchFormData.promoCode :
		null

	if (selectedFlight &&
		selectedFlight.priceClass === 'CUSTOMER_BSA_RATE') {
		promoCode = 'BSA'
	}
	else if (selectedFlight &&
		selectedFlight.priceClass === 'CUSTOMER_CPA_RATE') {
		promoCode = 'CPA'
	}
	else if (selectedFlight &&
		selectedFlight.priceClass === 'SPECIAL_RATE'
		&& !promoCode) {
		promoCode = 'SPECIAL'
	}
	// else if (selectedFlight &&
	// 	selectedFlight.priceClass !== 'CUSTOMER_RATE') {
	// 		promoCode = null
	// }
	let filteredDimensions = searchFormData?.cages?.map((item) => {
		let items = {};
		items[LOOSE_ITEM_KEYS.LENGTH] = item.length
		items[LOOSE_ITEM_KEYS.WIDTH] = item.width
		items[LOOSE_ITEM_KEYS.HEIGHT] = item.height
		items[LOOSE_ITEM_KEYS.PIECES] = item.pieces
		items[LOOSE_ITEM_KEYS.WEIGHT] = item.weight
		items[LOOSE_ITEM_KEYS.TILTABLE] = 'N'
		items[LOOSE_ITEM_KEYS.STACKABLE] = 'N'
		return items;
	})

	// let filteredCageData = searchFormData?.cages?.map((item) => {
	// 	delete item['length']
	// 	delete item['width']
	// 	delete item['height']
	// 	delete item['pieces']
	// 	delete item['weight']
	// 	return item;
	// })
	let unidInfoArray = [];
	let unidArrayNameList = [];
	// let attachmentID = searchFormData?.dgAttachementDetails[0]?.attachmentId;

	let specialComArray = []
	const dgCheckNormal = getFromSessionStorage('ifDg')
	const dgSccCode = JSON.parse(getFromSessionStorage('dgsccCode'))
	if (searchFormData?.dgAttachementDetails) {
		searchFormData?.dgAttachementDetails.filter(item => {
			specialComArray.push({
				attachmentId: item?.attachmentId,
				fileName: item?.fileName,
				id: item?.id
			})
		})
	}
	if (searchFormData?.unid) {

		searchFormData?.unid?.filter(item => {

			unidInfoArray.push({
				id: item?.id,
				unid: item?.unid,
				shippingName: item?.unidName,
				packingGroup: item?.packingGroup,

			})

		})
	}


	const spotRateCode = (selectedFlight &&
		selectedFlight.priceClass === 'ADHOC_RATE') && searchFormData.spotRateCode ?
		searchFormData.spotRateCode : 
		searchFormData.spotRateDetails && searchFormData.spotRateDetails.spotRateCode
			? searchFormData.spotRateDetails.spotRateCode
			: null

	requestBody = {
		requestID: uuidv4(),
		operationalFlag: !searchFormData.isModifyBooking ? 'I' : null,
		bookingDetails: {
			shipmentIdentifierDetails: null,
			weightUnit: WEIGHT_UNIT.KILOGRAM,
			volumeUnit: DIMENSION_UNIT.METERS,
			dimensionUnit: searchFormData.dimensionUnit,
			promoCode,
			spotRateCode,
			shipmentDetails: {
				shipmentOrigin: searchFormData.origin
					? searchFormData.origin.code
					: null,
				shipmentDestination: searchFormData.destination
					? searchFormData.destination.code
					: null,
				shippingDate: getShippingDate(searchFormData, selectedFlight),
				agentCode: userDetails ? userDetails.agentCode : null,
				customerCode: userDetails ? userDetails.customerCode : null,
				currency: getCurrency(searchFormData, selectedFlight),
				productDetails: {
					productName: (selectedFlight && selectedFlight.ratingDetails && selectedFlight.ratingDetails.length) ?
						selectedFlight.ratingDetails[0].product : searchFormData.shippingItemCode.product,
				},
			},
			sccCodes:
				selectedFlight && selectedFlight.service
					? [...sccCodes, selectedFlight.service]
					: sccCodes,
			bookingCommodityDetails:
				[
					{
						commodityCode: searchFormData.shippingItemCode
							? searchFormData.shippingItemCode.code
							: '',
						pieces: searchFormData.totalPieces,
						weight: Number(searchFormData.totalWeightInKg),
						volume:
							Number(searchFormData.totalVolumeInMtrs).toFixed(2) > 0.01
								? Number(searchFormData.totalVolumeInMtrs).toFixed(2)
								: 0.01,
						shipmentDescription: searchFormData.shippingItemCode
							? searchFormData.shippingItemCode.description
							: '',
						adjustedWeight: searchFormData?.adjustedWeight
							? round(
								calculateWeightToKg(
									Number(searchFormData?.adjustedWeight),
									searchFormData?.weightUnit
								),
								2
							)
							: 0,
					},
				],
			bookingFlightDetails: getBookingFlightDetails(
				searchFormData,
				selectedFlight
			),
			spotRate:
				spotRatePayload &&
					spotRatePayload.isSpotRateRequired &&
					Number(spotRatePayload.spotRate)
					? Number(spotRatePayload.spotRate).toFixed(4)
					: searchFormData.spotRateDetails && searchFormData.spotRateDetails.spotRate
						? Number(searchFormData.spotRateDetails.spotRate).toFixed(4) : null,
			allInRate:
				spotRatePayload &&
					spotRatePayload.isSpotRateRequired &&
					Number(spotRatePayload.spotRate)
					? spotRatePayload.allInRate
					: searchFormData.spotRateDetails && searchFormData.spotRateDetails.allInRate
						? searchFormData.spotRateDetails.allInRate : null,
			isSpotRateRequired:
				spotRatePayload &&
					spotRatePayload.isSpotRateRequired &&
					Number(spotRatePayload.spotRate)
					? 'Y'
					: searchFormData.spotRateDetails && searchFormData.spotRateDetails.spotRate ? 'Y'
					: 'N',
			spotRateRequestedUser: searchFormData.spotRateDetails && searchFormData.spotRateDetails.spotRateRequestedUser
				? searchFormData.spotRateDetails.spotRateRequestedUser : null,
			spotRateStatus: searchFormData.spotRateDetails && searchFormData.spotRateDetails.spotRateStatus
				? searchFormData.spotRateDetails.spotRateStatus : null,
			specializedCommodity: specialComArray,
			additionalCommodityDetails: ((dgSccCode?.includes(searchFormData?.shippingItemCode?.scc)) || (dgCheckNormal && dgCheckNormal === 'true')) ?
				{
					dangerousGoods: true,
					specialProvision: true,
					unidInfo: unidInfoArray
				} : null,
			handlingInformation: searchFormData?.handlingInformation,
		},
	}
	if (searchFormData?.crates[0]?.weight) {
		requestBody.bookingDetails.cages = searchFormData.crates
	}
	if (searchFormData.isModifyBooking) {
		requestBody.bookingDetails.deletedCageList = searchFormData.deletedCageIds
		requestBody.bookingDetails.deletedPetList = searchFormData.deletedPetIds
	}
	if (awbNumber && awbNumber.length) {
		requestBody.bookingDetails.shipmentIdentifierDetails = {}
		requestBody.bookingDetails.shipmentIdentifierDetails.shipmentPrefix = spotRatePayload && spotRatePayload.awbPrefix ? spotRatePayload.awbPrefix : awbNumber.trim().substring(0, 3).trim()
		requestBody.bookingDetails.shipmentIdentifierDetails.masterDocumentNumber =
		spotRatePayload && spotRatePayload.awbPrefix ?  awbNumber.trim() : awbNumber.trim().substring(3).trim()
	}
	if (searchFormData.shippingType === SHIPPING_TYPE.LOOSE) {
		requestBody.bookingDetails.bookingCommodityDetails[0].dimensionDetaills = []
		searchFormData.looseDetails.forEach(function (element) {
			if (element.pieces > 0) {
				const weight = Number(element.weight) ? Number(element.weight).toFixed(2) : 0
				const weightType = !Number(element.weight)
					? DETAILS_APPLY_FOR.TOTAL
					: element.weightType
				let formattedItemDetail = {
					length: element.length,
					height: element.height,
					width: element.width,
					numberOfPieces: element.pieces,
					weight:
						weightType === DETAILS_APPLY_FOR.PER_ITEM
							? weight * element.pieces
							: weight,
					tiltable: !element.turnable ? 'N' : 'Y',
					stackable: !element.stackable ? 'N' : 'Y',
				}
				requestBody.bookingDetails.bookingCommodityDetails[0].dimensionDetaills.push(
					formattedItemDetail
				)
			}
		})
	} else {
		requestBody.bookingDetails.bookingCommodityDetails[0].uldInfoDetaills = []
		searchFormData.ULDDetails.forEach(function (element) {
			if (element.pieces > 0) {
				let formattedItemDetail = {
					uldType: element.uldType,
					numberOfUlds: element.pieces,
					uldWeight: element.weight,
					uldVolume: element.uldVolume,
					contour: element.contour ? element.contour : null,
				}
				requestBody.bookingDetails.bookingCommodityDetails[0].uldInfoDetaills.push(
					formattedItemDetail
				)
			}
		})
		if (
			searchFormData.mixLooseDetails &&
			searchFormData.mixLooseDetails.length > 0 &&
			searchFormData.mixLooseDetails[0].weight !== ''
		) {
			requestBody.bookingDetails.bookingCommodityDetails[0].dimensionDetaills =
				[]
			searchFormData.mixLooseDetails.forEach(function (element) {
				if (element.pieces > 0) {
					const weight = !Number(element.weight)
						? Number(searchFormData.totalWeightInKg) /
						searchFormData.mixLooseDetails.length
						: Number(element.weight)
					const weightType = !Number(element.weight)
						? DETAILS_APPLY_FOR.TOTAL
						: element.weightType
					let formattedItemDetail = {
						length: element.length,
						height: element.height,
						width: element.width,
						numberOfPieces: element.pieces,
						weight:
							weightType === DETAILS_APPLY_FOR.PER_ITEM
								? weight * element.pieces
								: weight,
						tiltable: !element.turnable ? 'N' : 'Y',
						stackable: !element.stackable ? 'N' : 'Y',
					}
					requestBody.bookingDetails.bookingCommodityDetails[0].dimensionDetaills.push(
						formattedItemDetail
					)
				}
			})
		}
	}
	return requestBody
}

export const getSimilarBookingsRequestBody = (origin) => {
	const userDetails = getCurrentUser()
	let requestBody = {
		requestID: uuidv4(),
		origin: origin.code,
		fromBookingDate: moment().subtract(SIMILAR_BOOKING_SUGGESTIONS_PERIOD_DAYS, 'days').format('DD-MMM-YYYY'),
		pageNumber: 1,
		agentCode: userDetails ? userDetails.agentCode : null,
	}
	return requestBody
}

export const getBookingListRequestBody = (bookingListRequest) => {
	const userDetails = getCurrentUser()
	const bookingSearchFormData = bookingListRequest.bookingSearchFormData
	const pageNumber = bookingListRequest.pageNumber
	const origin =
		bookingListRequest.defaultOrigin !== null
			? bookingListRequest.defaultOrigin.code
			: bookingSearchFormData.origin !== null
				? bookingSearchFormData.origin.code
				: null
	let requestBody = {
		origin: origin,
		destination:
			bookingSearchFormData.destination !== null
				? bookingSearchFormData.destination.code
				: null,

		shipmentIdentifierDetails:
			bookingSearchFormData.shipmentIdentifierDetails &&
				bookingSearchFormData.shipmentIdentifierDetails.shipmentPrefix &&
				bookingSearchFormData.shipmentIdentifierDetails.masterDocumentNumber
				? bookingSearchFormData.shipmentIdentifierDetails
				: null,

		bookingStatus: bookingSearchFormData.bookingStatus,
		product:
			bookingSearchFormData.product &&
				bookingSearchFormData.product.name &&
				bookingSearchFormData.product.name !== 'All'
				? bookingSearchFormData.product.name
				: null,
		carrierCode: bookingSearchFormData.carrierCode
			? bookingSearchFormData.carrierCode
			: null,
		flightNumber:
			bookingSearchFormData.flightNumber && bookingSearchFormData.carrierCode
				? bookingSearchFormData.flightNumber
				: null,
		flightDate: bookingSearchFormData.flightDate
			? moment(bookingSearchFormData.flightDate).format('DD-MMM-YYYY')
			: null,

		fromBookingDate: bookingSearchFormData.fromBookingDate
			? moment(bookingSearchFormData.fromBookingDate).format('DD-MMM-YYYY')
			: null,
		toBookingDate: bookingSearchFormData.toBookingDate
			? moment(bookingSearchFormData.toBookingDate).format('DD-MMM-YYYY')
			: moment(new Date()).format('DD-MMM-YYYY'),
		pageNumber: pageNumber ? pageNumber : 1,
		requestID: uuidv4(),
		agentCode: userDetails ? userDetails.agentCode : null,
	}
	return requestBody
}

const getShippingDate = (searchFormData, selectedFlight) => {
	if (searchFormData.isModifyBooking && !selectedFlight) {
		const shippingDate = searchFormData.shippingDate
			? moment(searchFormData.shippingDate, 'DD-MMM-YYYY').format('DD-MMM-YYYY')
			: null
		return shippingDate
	} else if (selectedFlight) {
		const shippingDate = selectedFlight.departureDateTime
			? moment(selectedFlight.departureDateTime, 'DD-MMM-YYYY').format('DD-MMM-YYYY')
			: null
		return shippingDate
	}
}

const getCurrency = (searchFormData, selectedFlight) => {
	if (searchFormData.isModifyBooking && !selectedFlight) {
		return searchFormData.currency
	} else if (selectedFlight && selectedFlight.ratingDetails) {
		return selectedFlight.ratingDetails[0].currency
	} else if (selectedFlight && !selectedFlight.ratingDetails) {

		return 'AED'
	}
}

const getBookingFlightDetails = (searchFormData, selectedFlight) => {
	if (searchFormData.isModifyBooking && !selectedFlight) {
		return searchFormData.bookingFlightDetails
	} else if (selectedFlight) {
		const flightDetails = selectedFlight.flightSegmentList.map((flight) => ({
			segmentOrigin: flight.segmentOrigin,
			segmentDestination: flight.segmentDestination,
			segmentArrivalDate: flight.arrivalDateTime,
			segmentDepartureDate: flight.departureDateTime,
			carrierCode: flight.flightCarrierCode,
			flightNumber: flight.flightNumber,
			flightDate: flight.flightDate,
		}))
		return flightDetails
	}
}

export const getSaveHAWBRequest = (hawbRequest) => {
	let requestBody = {
		requestID: hawbRequest.requestID,
		awbDetails: {
			hawbNumber: hawbRequest.hawbNumber ? hawbRequest.hawbNumber.toUpperCase() : null,
			operationalFlag: hawbRequest.operationalFlag,

			shipmentIdentifierDetails:
				hawbRequest.awbDetails.shipmentPrefix &&
					hawbRequest.awbDetails.masterDocumentNumber
					? {
						shipmentPrefix: hawbRequest.awbDetails.shipmentPrefix,
						masterDocumentNumber: hawbRequest.awbDetails.masterDocumentNumber,
					}
					: null,
			commodityCode: hawbRequest?.houseDetails ? hawbRequest?.houseDetails[0]?.awbRateLineDetails?.awbRateLine[0]?.commodityCode : null,
			shipmentDetails: {
				shipmentOrigin:
					hawbRequest.shipmentDetails.origin !== null
						? hawbRequest.shipmentDetails.origin.code
						: null,
				shipmentDestination:
					hawbRequest.shipmentDetails.destination !== null
						? hawbRequest.shipmentDetails.destination.code
						: null,
				totalNumberOfPieces: hawbRequest.shipmentDetails.pieces,
				totalWeight:
					hawbRequest.shipmentDetails.weight &&
						hawbRequest.shipmentDetails.weightUnit
						? round(
							calculateWeightToKg(
								hawbRequest.shipmentDetails.weight,
								hawbRequest.shipmentDetails.weightUnit
							), 2
						)
						: 0,
				weightUnit: WEIGHT_UNIT.KILOGRAM,
				shipmentDescription: hawbRequest.shipmentDetails.shipmentDescription
					? (hawbRequest.shipmentDetails.shipmentDescription).toUpperCase()
					: null,
				slacPieces: hawbRequest.shipmentDetails.slacPieces
					? hawbRequest.shipmentDetails.slacPieces
					: null,
				totalVolume:
					hawbRequest.shipmentDetails.volume &&
						hawbRequest.shipmentDetails.volumeUnit
						? calculateCbmVolume(
							hawbRequest.shipmentDetails.volume,
							hawbRequest.shipmentDetails.volumeUnit
						)
						: 0,
				volumeUnit: VOLUME_UNIT_VALUES.CUBIC_METERS,
				scc: hawbRequest.shipmentDetails.scc
					? hawbRequest.shipmentDetails.scc
					: null,
				declaredValueForCustoms: hawbRequest.shipmentDetails
					.declaredValueForCustoms
					? hawbRequest.shipmentDetails.declaredValueForCustoms
					: null,
				awbCurrencyCode: hawbRequest.shipmentDetails.currencyCode
					? hawbRequest.shipmentDetails.currencyCode
					: null,
				paymentType: hawbRequest.shipmentDetails.paymentType
					? hawbRequest.shipmentDetails.paymentType
					: null,
				handlingInfo: hawbRequest.shipmentDetails.handlingInfo
					? (hawbRequest.shipmentDetails.handlingInfo).toUpperCase()
					: null,
			},


			shipperDetails: {
				name: hawbRequest.shipperDetails.shipperName
					? (hawbRequest.shipperDetails.shipperName).toUpperCase()
					: null,
				address: hawbRequest.shipperDetails.shipperAddress1
					? (hawbRequest.shipperDetails.shipperAddress1).toUpperCase()
					: null,
				city: hawbRequest.shipperDetails.shipperCity
					? (hawbRequest.shipperDetails.shipperCity).toUpperCase()
					: null,
				state: hawbRequest.shipperDetails.shipperState
					? (hawbRequest.shipperDetails.shipperState).toUpperCase()
					: null,
				country: hawbRequest.shipperDetails.shipperCountry
					? hawbRequest.shipperDetails.shipperCountry.countryCode
					: null,
				postalCode: hawbRequest.shipperDetails.shipperPostalCode
					? (hawbRequest.shipperDetails.shipperPostalCode).toUpperCase()
					: null,
				accountNumber: hawbRequest.shipperDetails.shipperAccountNumber
					? (hawbRequest.shipperDetails.shipperAccountNumber).toUpperCase()
					: null,
				phoneNumber: hawbRequest.shipperDetails.shipperPhone
					? hawbRequest.shipperDetails.shipperPhone
					: null,
				email: hawbRequest.shipperDetails.shipperMail
					? (hawbRequest.shipperDetails.shipperMail).toUpperCase()
					: null,
			},

			consigneeDetails: {
				name: hawbRequest.consigneeDetails.consigneeName
					? (hawbRequest.consigneeDetails.consigneeName).toUpperCase()
					: null,
				address: hawbRequest.consigneeDetails.consigneeAddress1
					? (hawbRequest.consigneeDetails.consigneeAddress1).toUpperCase()
					: null,
				city: hawbRequest.consigneeDetails.consigneeCity
					? (hawbRequest.consigneeDetails.consigneeCity).toUpperCase()
					: null,
				state: hawbRequest.consigneeDetails.consigneeState
					? (hawbRequest.consigneeDetails.consigneeState).toUpperCase()
					: null,
				country: hawbRequest.consigneeDetails.consigneeCountry
					? hawbRequest.consigneeDetails.consigneeCountry.countryCode
					: null,
				postalCode: hawbRequest.consigneeDetails.consigneePostalCode
					? (hawbRequest.consigneeDetails.consigneePostalCode).toUpperCase()
					: null,
				accountNumber: hawbRequest.consigneeDetails.consigneeAccountNumber
					? (hawbRequest.consigneeDetails.consigneeAccountNumber).toUpperCase()
					: null,
				phoneNumber: hawbRequest.consigneeDetails.consigneePhone
					? hawbRequest.consigneeDetails.consigneePhone
					: null,
				email: hawbRequest.consigneeDetails.consigneeMail
					? (hawbRequest.consigneeDetails.consigneeMail).toUpperCase()
					: null,
			},
			agentDetails: hawbRequest.airwayBillDetails.awbAgentDetails
				? hawbRequest.airwayBillDetails.awbAgentDetails
				: null,
			awbOriginDestination:
				hawbRequest.airwayBillDetails.awbConsignmentDetails
					.awbOriginDestination,
			quantityDetails:
				hawbRequest.airwayBillDetails.awbConsignmentDetails.quantityDetails,
			otherCustomsDetails: {
				sci: checkBlankField(hawbRequest.otherCustomsDetails?.sci),
				hccs:
					hawbRequest.otherCustomsDetails?.hccs && hawbRequest.otherCustomsDetails?.hccs.length
						? hawbRequest.otherCustomsDetails.hccs.split(',')
						: null,
				uldNo: null,
				customsDeclaredValue: null,
				handlingInfo: hawbRequest.shipmentDetails.handlingInfo
					? (hawbRequest.shipmentDetails.handlingInfo).toUpperCase()
					: null,
				carrierDeclaredValue: hawbRequest.awbChargeDeclaration
					.declaredValueForCarriage
					? hawbRequest.awbChargeDeclaration?.declaredValueForCarriage
					: null,
			},
			customsInformation: getHawbCustomsInformationDetails(
				hawbRequest.customsInformationDetails
			),
		},
	}
	return requestBody
}

export const getSaveAwbRequest = (awbDetails) => {
	const dimensionDetails = getDimensionDetails(
		awbDetails,
		awbDetails.shippingType === SHIPPING_TYPE.LOOSE
			? awbDetails.looseDetails
			: awbDetails.mixLooseDetails
	)
	let newsccCodes = [].concat(awbDetails.shipmentSccs, awbDetails.additionalSccs, awbDetails.multipleSccList);
	let freightCharge = awbDetails.awbRateLineDetails.freightCharge
	? parseFloat(awbDetails.awbRateLineDetails.freightCharge)
	: 0
	if (freightCharge === 0) {
		freightCharge = awbDetails.awbRateLineDetails.netCharge
		? parseFloat(awbDetails.awbRateLineDetails.netCharge).toFixed(2)
		: 0
	}
	const request = {
		requestID: getRandomIntInclusive(10000, 99999),
		awbDetails: {
			shipmentIdentifierDetails: {
				shipmentPrefix: awbDetails.awb.shipmentIdentifierDetails.shipmentPrefix,
				masterDocumentNumber:
					awbDetails.awb.shipmentIdentifierDetails.awbDocumentNumber,
			},
			agentDetails: {
				agentCode: awbDetails.agentDetails.code,
				agentName: awbDetails.agentDetails.name,
				iataCode: awbDetails.agentDetails.IATACode,
				stationCode: awbDetails.agentDetails.stationCode,
			},
			shipmentDetails: {
				shipmentOrigin: awbDetails.origin.code,
				shipmentDestination: awbDetails.destination.code,
				shippingDate: awbDetails.shippingDate
					? moment(awbDetails.shippingDate, 'DD-MMM-YYYY').format('DD-MMM-YYYY')
					: null,
				flightNumber: awbDetails.flightNumber,
				ubrNumber: awbDetails.ubrNumber,
				flightCarrierCode: awbDetails.flightCarrierCode,
				totalNumberOfPieces: awbDetails.totalPieces
					? parseInt(awbDetails.totalPieces)
					: 0,
				totalWeight: awbDetails.totalWeight
					? round(
						calculateWeightToKg(
							parseFloat(awbDetails.totalWeight),
							awbDetails.weightUnit
						), 2
					)
					: 0,
				productDetails: {
					productName: awbDetails.shippingItemCode.product,
				},
				shipmentDescription: checkBlankField(awbDetails.shipmentDescriptionData.shipmentDescription.toUpperCase()),
				/**sccCodes:
					awbDetails.sccCodes && awbDetails.sccCodes.length
						? awbDetails.sccCodes.map((e) => e.scc)
						: [],**/
				sccCodes:
					newsccCodes && newsccCodes.length
						? newsccCodes.map((e) => e.scc)
						: [],
				weightUnit: awbDetails.weightUnit,
				volumeUnit: awbDetails.volumeUnit,
				dimensionUnit: awbDetails.dimensionUnit,
				slacPieces: awbDetails.slacPieces ? parseInt(awbDetails.slacPieces) : 0,
				chargableWeight: Number(awbDetails.chargeableWeightInSelectedUnit)
					? Number(awbDetails.chargeableWeightInSelectedUnit).toFixed(2)
					: 0,
				// chargableWeight: awbDetails.chargeableWeightInSelectedUnit,
				routes: getRouting(awbDetails.awbRouting),
			},
			promoCode: awbDetails.promoCode,
			commodityDetails: {
				commodityCode: awbDetails.shippingItemCode
					? awbDetails.shippingItemCode.code
					: '',
				sccDetails: {
					sccCode:
						awbDetails.sccCodes && awbDetails.sccCodes.length
							? awbDetails.sccCodes[0].scc
							: '',
				},
				pieces: awbDetails.totalPieces ? awbDetails.totalPieces + '' : '',
				weight: Number(awbDetails.totalWeightInKg).toFixed(2),
				volume: awbDetails.displayVolume
					? Number(calculateCbmVolume(awbDetails.displayVolume, awbDetails.volumeUnit)).toFixed(2)
					: 0.01,
				dimensionDetaills: dimensionDetails,
				uldInfoDetails: awbDetails.shippingType === SHIPPING_TYPE.ULD ? getULDDetails(awbDetails.ULDDetails) : null,
			},
			shipperDetails: {
				name: checkBlankField((awbDetails.shipperDetails.name).toUpperCase()),
				address: checkBlankField((awbDetails.shipperDetails.address).toUpperCase()),
				city: checkBlankField((awbDetails.shipperDetails.city).toUpperCase()),
				state: checkBlankField((awbDetails.shipperDetails.state).toUpperCase()),
				country:
					awbDetails.shipperDetails.countryCode &&
						awbDetails.shipperDetails.countryCode.countryCode
						? checkBlankField(awbDetails.shipperDetails.countryCode.countryCode)
						: null,
				postalCode: checkBlankField(awbDetails.shipperDetails.zipCode ? (awbDetails.shipperDetails.zipCode).toUpperCase() : null),
				phoneNumber: checkBlankField(awbDetails.shipperDetails.telephoneNo),
				email: checkBlankField(awbDetails.shipperDetails.emailAddress ? (awbDetails.shipperDetails.emailAddress).toUpperCase() : null),
				accountNumber: checkBlankField(awbDetails.shipperDetails.accountNumber ? (awbDetails.shipperDetails.accountNumber).toUpperCase() : null),
			},
			consigneeDetails: {
				name: checkBlankField((awbDetails.consigneeDetails.name).toUpperCase()),
				address: checkBlankField((awbDetails.consigneeDetails.address).toUpperCase()),
				city: checkBlankField((awbDetails.consigneeDetails.city).toUpperCase()),
				state: checkBlankField((awbDetails.consigneeDetails.state).toUpperCase()),
				country:
					awbDetails.consigneeDetails.countryCode &&
						awbDetails.consigneeDetails.countryCode.countryCode
						? checkBlankField(awbDetails.consigneeDetails.countryCode.countryCode)
						: null,
				postalCode: checkBlankField(awbDetails.consigneeDetails.zipCode ? (awbDetails.consigneeDetails.zipCode).toUpperCase() : null),
				phoneNumber: checkBlankField(awbDetails.consigneeDetails.telephoneNo),
				email: checkBlankField(awbDetails.consigneeDetails.emailAddress ? (awbDetails.consigneeDetails.emailAddress).toUpperCase() : null),
				accountNumber: checkBlankField(awbDetails.consigneeDetails.accountNumber ? (
					awbDetails.consigneeDetails.accountNumber).toUpperCase() : null
				),
			},
			notificationRecieverDetails: getNotificationDetails(
				awbDetails.notifyList,
				awbDetails
			),
			rateDetails: {
				rate: awbDetails.awbRateLineDetails.rate
					? parseFloat(awbDetails.awbRateLineDetails.rate).toFixed(4)
					: 0,
				rateClass: awbDetails.awbRateLineDetails.rateClassCode
					? awbDetails.awbRateLineDetails.rateClassCode
					: 0,
				freightCharge: freightCharge,
				netRate: awbDetails.awbRateLineDetails.netCharge
					? parseFloat(awbDetails.awbRateLineDetails.netCharge).toFixed(2)
					: 0,
				paymentType: awbDetails.awbRateLineDetails.paymentType,
				totalOtherCharge: parseFloat(awbDetails.awbRateLineDetails.surcharges),
				totalCharge: parseFloat(awbDetails.awbRateLineDetails.totalCharge),
				currencyCode: checkBlankField(awbDetails.currency),
				otherChargeDetails: getOtherChargeDetails(
					awbDetails.awbRateLineDetails.otherCharges
				),
				airlineTariffName: awbDetails.awbRateLineDetails.airlineTariffName,
				airlineTariffSerialNumber:
					awbDetails.awbRateLineDetails.airlineTariffSerialNumber,
				airlineTariffRateSerialNumber:
					awbDetails.awbRateLineDetails.airlineTariffRateSerialNumber,
				airlineRateLineId: awbDetails.awbRateLineDetails.airlineRateLineId,
				airlineRatingType: awbDetails.awbRateLineDetails.airlineRatingType,
			},
			accountingInformations: getAccountingInformation(
				awbDetails.accountingInformationDetails
			),
			otherCustomsDetails: {
				sci: checkBlankField(awbDetails.sci),
				hccs:
					awbDetails.hccs && awbDetails.hccs.length
						? (awbDetails.hccs.toUpperCase().split(','))
						: null,
				uldNo: null,
				customsDeclaredValue: awbDetails.awbChargeDeclaration
					.declaredValueForCustoms
					? awbDetails.awbChargeDeclaration.declaredValueForCustoms
					: null,
				carrierDeclaredValue: awbDetails.awbChargeDeclaration
					.declaredValueForCarriage
					? awbDetails.awbChargeDeclaration.declaredValueForCarriage
					: null,
				handlingInfo:
					awbDetails.handlingInfo && awbDetails.handlingInfo !== ''
						? (awbDetails.handlingInfo).toUpperCase()
						: null,
			},
			customsInformation: getCustomsInformationDetails(
				awbDetails.customsInformationDetails
			),
			awbExecutionDetails: {
				awbIssueDate: awbDetails.awbExecutionDetails.issueDate
					? moment(awbDetails.awbExecutionDetails.issueDate).format(
						'DD-MMM-yyyy hh:mm:ss'
					)
					: null,
				placeOfExecution: checkBlankField(
					(awbDetails.awbExecutionDetails.issuedPlace).toUpperCase()
				),
				executedUser: checkBlankField((awbDetails.awbExecutionDetails.issuedBy).toUpperCase()),
			},
			promoCode: awbDetails.promoCode ? awbDetails.promoCode.toUpperCase() : null,
			detachSpotRate: awbDetails.detachSpotRate ? awbDetails.detachSpotRate : false,
		},
	}
	return request
}

const getCustomsInformationDetails = (customsInformationDetails) => {
	let customsDetails = []
	if (customsInformationDetails && customsInformationDetails.length) {
		customsInformationDetails.forEach(function (info) {
			if (
				isNotBlank(info.countryCode) &&
				isNotBlank(info.customsInformationId) &&
				isNotBlank(info.informationId) &&
				isNotBlank(info.supplementaryCustomsInformation)
			) {
				const customsInfo = {
					informationId: checkBlankField((info.informationId).toUpperCase()),
					customsInformationId: checkBlankField((info.customsInformationId).toUpperCase()),
					supplementaryCustomsInformation: checkBlankField(
						(info.supplementaryCustomsInformation).toUpperCase()
					),
					countryCode: checkBlankField((info.countryCode).toUpperCase()),
				}
				customsDetails.push(customsInfo)
			}
		})
		return customsDetails.length > 0 ? customsDetails : null
	} else {
		return null
	}
}

const getHawbCustomsInformationDetails = (customsInformationDetails) => {
	let customsDetails = []
	if (customsInformationDetails && customsInformationDetails.length) {
		customsInformationDetails.forEach(function (info) {
			if (
				isNotBlank(info.countryCode) &&
				isNotBlank(info.customsInformationId) &&
				isNotBlank(info.informationId) &&
				isNotBlank(info.supplementaryCustomsInformation)
			) {
				const customsInfo = {
					informationId: checkBlankField((info.informationId).toUpperCase()),
					customsInformationId: checkBlankField((info.customsInformationId).toUpperCase()),
					supplementaryCustomsInformation: checkBlankField(
						(info.supplementaryCustomsInformation).toUpperCase()
					),
					countryCode: checkBlankField((info.countryCode).toUpperCase()),
				}
				customsDetails.push(customsInfo)
			}
		})
		return customsDetails.length > 0 ? customsDetails : []
	} else {
		return []
	}
}

const getAccountingInformation = (accountingInformationDetails) => {
	let accountingDetails = []
	if (accountingInformationDetails && accountingInformationDetails.length) {
		accountingInformationDetails.forEach(function (info) {
			if (
				isNotBlank(info.accountingInformationIdentifier) &&
				isNotBlank(info.accountingInformation)
			) {
				const accountingInformation = {
					accountingInformationIdentifier: checkBlankField(
						(info.accountingInformationIdentifier).toUpperCase()
					),
					accountingInformation: checkBlankField(info.accountingInformation),
				}
				accountingDetails.push(accountingInformation)
			}
		})
		return accountingDetails.length > 0 ? accountingDetails : null
	} else {
		return null
	}
}

const getDimensionDetails = (awbDetails, itemDetails) => {
	const dimensions = []
	if (itemDetails && itemDetails.length) {
		itemDetails.forEach(function (element) {
			const weight = Number(element.weight)
				? Number(element.weight).toFixed(2)
				: 0
			const weightType = !Number(element.weight)
				? DETAILS_APPLY_FOR.TOTAL
				: element.weightType
			if (element.pieces > 0) {
				const item = {
					length: element.length ? element.length.toString() : '',
					height: element.height ? element.height.toString() : '',
					width: element.width ? element.width.toString() : '',
					numberOfPieces: element.pieces ? element.pieces.toString() : '',
					weight:
						weightType === DETAILS_APPLY_FOR.PER_ITEM
							? weight * element.pieces
							: weight,
					tiltable: !element.turnable ? 'N' : 'Y',
					stackable: !element.stackable ? 'N' : 'Y',
					weightUnit: awbDetails.weightUnit,
					dimensionUnit: awbDetails.dimensionUnit,
					volumeUnit: awbDetails.volumeUnit,
				}
				dimensions.push(item)
			}
		})
		return dimensions && dimensions.length > 0 ? dimensions : null
	} else {
		return null
	}
}

const getULDDetails = (uldDetails) => {
	const uldDetailsInfo = [];
	if (uldDetails && uldDetails.length) {
		uldDetails.forEach(function (uld) {
			if (uld.pieces && uld.pieces > 0) {
				const uldInfo = {
					uldType: uld.uldType,
					numberOfUlds:
						uld.pieces && uld.pieces !== '' ? parseInt(uld.pieces) : 1,
					uldWeight:
						uld.weight && uld.weight !== ''
							? Number(uld.weight).toFixed(2)
							: null,
				}
				uldDetailsInfo.push(uldInfo)
			}
		})
		return uldDetailsInfo.length > 0 ? uldDetailsInfo : null
	} else {
		return null
	}
}

const getNotificationDetails = (notifyList, awbDetails) => {
	let noficationDetails = []
	if (notifyList) {
		if (notifyList.notifyShipper.checked) {
			const notificationRecieverDetails = {
				name: checkBlankField(awbDetails.shipperDetails.name ? (awbDetails.shipperDetails.name).toUpperCase() : null),
				phoneNumber: checkBlankField(awbDetails.shipperDetails.telephoneNo ? (awbDetails.shipperDetails.telephoneNo).toUpperCase() : null),
				email: checkBlankField(awbDetails.shipperDetails.emailAddress ? (awbDetails.shipperDetails.emailAddress).toUpperCase() : null),
				role: 'S',
			}
			noficationDetails.push(notificationRecieverDetails)
		}
		if (notifyList.notifyConsignee.checked) {
			const notificationRecieverDetails = {
				name: checkBlankField(awbDetails.consigneeDetails.name ? (awbDetails.consigneeDetails.name).toUpperCase() : null),
				phoneNumber: checkBlankField(awbDetails.consigneeDetails.telephoneNo ? (awbDetails.consigneeDetails.telephoneNo).toUpperCase() : null),
				email: checkBlankField(awbDetails.consigneeDetails.emailAddress ? (awbDetails.consigneeDetails.emailAddress).toUpperCase() : null),
				role: 'C',
			}
			noficationDetails.push(notificationRecieverDetails)
		}
		if (notifyList.notifyOther.checked) {
			if ((notifyList.notifyOther.name && notifyList.notifyOther.name !== '')) {
				const notificationRecieverDetails = {
					name: checkBlankField(notifyList.notifyOther.name ? (notifyList.notifyOther.name).toUpperCase() : null),
					phoneNumber: checkBlankField(notifyList.notifyOther.telephoneNo ? (notifyList.notifyOther.telephoneNo).toUpperCase() : null),
					email: checkBlankField(notifyList.notifyOther.email ? (notifyList.notifyOther.email).toUpperCase() : null),
					role: 'X',
					address: checkBlankField(notifyList.notifyOther.address ? (notifyList.notifyOther.address).toUpperCase() : null),
					city: checkBlankField(notifyList.notifyOther.city ? (notifyList.notifyOther.city).toUpperCase() : null),
					country: checkBlankField(notifyList.notifyOther.country && notifyList.notifyOther.country.countryCode ? (notifyList.notifyOther.country.countryCode).toUpperCase() : null)
				}
				noficationDetails.push(notificationRecieverDetails)
			}
		}
	}
	return noficationDetails
}

const getOtherChargeDetails = (otherCharges) => {
	let otherChargeDetails = []
	if (otherCharges && otherCharges.length) {
		otherCharges.forEach(function (otherCharge) {
			const surcharge = {
				code: checkBlankField(otherCharge.otherChargeCode),
				name: checkBlankField(otherCharge.chargeHeadName),
				amount: otherCharge.chargeAmount
					? parseFloat(otherCharge.chargeAmount).toFixed(2)
					: 0.0,
				paymentType: otherCharges[0].prepaidCollectIndicator,
				dueCarrier:
					otherCharge.chargeEntitlementCodeParty &&
						otherCharge.chargeEntitlementCodeParty === 'C'
						? true
						: false,
				dueAgent:
					otherCharge.chargeEntitlementCodeParty &&
						otherCharge.chargeEntitlementCodeParty === 'A'
						? true
						: false,
			}
			otherChargeDetails.push(surcharge)
		})
	}
	return otherChargeDetails
}

const getRouting = (awbRouting) => {
	if (awbRouting) {
		awbRouting.forEach(function (route, index) {
			if (index !== 0) {
				if (route.airport === '' || route.carrierCode === '') {
					const awbRoutingTemp = awbRouting.filter((itemDetail, curr) => {
						return index !== curr
					})
					awbRouting = awbRoutingTemp
				}
			}
		})
	}
	return awbRouting
}

export const getSaveAcceptTnCRequest = (flag) => {
	const userDetails = getCurrentUser()
	const request = {
		requestId: uuidv4(),
		userId: userDetails.userId,
		acceptTnC: flag,
	}
	return request
}

export const getAwbPrintRequest = (shipmentIdentifierDetails, printName) => {
	const requestID = uuidv4()
	const masterDocumentNumber = shipmentIdentifierDetails.awbDocumentNumber
	return ({
		requestID,
		shipmentIdentifierDetails: {
			...shipmentIdentifierDetails,
			masterDocumentNumber,
		},
		printName,
	})
}

export const getSaveQuoteRequest = (formData, flightInfo) => {
	const userDetails = localStorage.getItem(sessionKeys.USER_DETAILS)
		? JSON.parse(localStorage.getItem(sessionKeys.USER_DETAILS))
		: null
	const formParams = formData
	const flightDetails = flightInfo

	formParams.flightDetails = flightDetails

	let quoteFromSession = getFromSessionStorage('quotationDetails')
	let quotationDetails = quoteFromSession !== null ? getBase64Decoded(quoteFromSession)
		: null

	let quoteRequest = {
		requestID: uuidv4(),
		bookingDetails: formParams,
		quoteDetails: {
			userId: userDetails ? userDetails.userId : null,
			agentCode: userDetails ? userDetails.agentCode : null,
			quoteId: quotationDetails ? quotationDetails.quotationId : null,
			quotationGeneratedDateTime: quotationDetails
				? quotationDetails.quotationGeneratedDateTime
				: null
		}
	}
	return quoteRequest
}

const checkBlankField = (field) => {
	return field && field.trim() !== '' ? field.trim() : null
}

const isNotBlank = (value) => {
	if (value && value.trim() !== '') {
		return true
	} else {
		return false
	}
}

export const getcheckShipmentIsBigOrOHGRequest = (searchFormData) => {
	const sccCodes =
		searchFormData.sccCodes && searchFormData.sccCodes.length
			? searchFormData.sccCodes.map((e) => e.scc)
			: []
	const userDetails = getCurrentUser()
	const requestBody = {
		agentCode: userDetails ? userDetails.agentCode : null,
		weightUnit: searchFormData.weightUnit,
		commodityDetails: [
			{
				commodityCode: searchFormData.shippingItemCode
					? searchFormData.shippingItemCode.code
					: '',
				sccDetails: {
					sccCode: sccCodes ? sccCodes[0] : '',
				},
				pieces: searchFormData.totalPieces,
				weight: Number(searchFormData.totalWeightInKg).toFixed(2),
				volume: searchFormData.totalVolumeInMtrs
					? searchFormData.totalVolumeInMtrs.toFixed(2)
					: 0.01,
			},
		],
	}
	if (searchFormData.shippingType === SHIPPING_TYPE.LOOSE) {
		requestBody.commodityDetails[0].dimensionDetaills =
			searchFormData.looseDetails && searchFormData.looseDetails.length
				? searchFormData.looseDetails.map((element) => {
					const weight = Number(element.weight) ? Number(element.weight) : 0
					const weightType = !Number(element.weight)
						? DETAILS_APPLY_FOR.TOTAL
						: element.weightType
					return {
						length: element.length,
						height: element.height,
						width: element.width,
						numberOfPieces: element.pieces,
						weight:
							weightType === DETAILS_APPLY_FOR.PER_ITEM
								? weight * element.pieces
								: weight,
						tiltable: !element.turnable ? 'N' : 'Y',
						stackable: !element.stackable ? 'N' : 'Y',
						weightUnit: searchFormData.weightUnit,
						dimensionUnit: searchFormData.dimensionUnit,
					}
				})
				: []
	}
	if (searchFormData.shippingType === SHIPPING_TYPE.ULD) {
		requestBody.commodityDetails[0].uldInfoDetails =
			searchFormData.ULDDetails && searchFormData.ULDDetails.length
				? searchFormData.ULDDetails.map((element) => {
					return {
						uldWeight: element.weight,
						uldType: element.uldType,
						numberOfUlds: element.pieces,
						uldVolume: element.uldVolume,
					}
				})
				: []
		if (
			searchFormData.mixLooseDetails &&
			searchFormData.mixLooseDetails.length > 0 &&
			searchFormData.mixLooseDetails[0].weight !== ''
		) {
			requestBody.commodityDetails[0].dimensionDetaills =
				searchFormData.mixLooseDetails && searchFormData.mixLooseDetails.length
					? searchFormData.mixLooseDetails.map((element) => {
						const weight = Number(element.weight) ? Number(element.weight) : 0
						const weightType = !Number(element.weight)
							? DETAILS_APPLY_FOR.TOTAL
							: element.weightType
						return {
							length: element.length,
							height: element.height,
							width: element.width,
							numberOfPieces: element.pieces,
							weight:
								weightType === DETAILS_APPLY_FOR.PER_ITEM
									? weight * element.pieces
									: weight,
							tiltable: !element.turnable ? 'N' : 'Y',
							stackable: !element.stackable ? 'N' : 'Y',
							weightUnit: searchFormData.weightUnit,
							dimensionUnit: searchFormData.dimensionUnit,
						}
					})
					: []
		}
	}
	return requestBody
}
